:root {
  --aqua: #0f889b;
  --lightAqua: #06b7d2;
  --grey: #6e6d6d;
  --bg: #f8f9fa;
}

.greyColor {
  color: var(--grey);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body {
  min-width: 320px;
  background-color: var(--bg) !important;
}

a, .likeAnchor {
  color: var(--aqua);
}

a:hover, .likeAnchor:hover {
  color: var(--lightAqua);
  cursor: pointer;
  text-decoration: none;
}

.bondsTable {
  background-color: white;
  border-bottom: 1px solid #dee2e6;
  color: var(--grey);
}

.bondsTable th {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  border: none;
  z-index: 2;
}

.bondsTable tr td:first-child {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}

.bondsTable tr:hover td, .bondCardBg {
  background-color: var(--bg);
}

.bondsTable td {
  white-space: nowrap;
  vertical-align: middle;
}

.wideContent {
  overflow: scroll;
}

.heading {
  color: var(--aqua);
  margin: 3rem 0;
  font-weight: 300;
}

.bondCardHeading {
  color: var(--aqua);
  margin-top: 1rem;
  font-weight: 300;
}

.jumbotron .heading {
  font-size: 3rem;
  margin: 0;
}

.navbar, .aquaBg {
  background-color: var(--aqua) !important;
}

nav a {
  color: #fff !important;
}

nav a:hover {
  color: #64dbff !important;
}

.topMenuActive {
  color: #64dbff !important;
}

.pointer {
  cursor: pointer;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.bondInfoTable {
  background-color: white;
  color: var(--grey);
}

.bondInfoTable tr:first-child td, .bondInfoTable tr:first-child th {
  border-top: 0;
}

.bondsTableActions {
  cursor: pointer;
  opacity: 0.5;
  text-align: center;
}

.bondsTable tr:hover .bondsTableActions {
  opacity: 1;
}

.inlineBlock {
  display: inline-block;
}

.tableNoData {
  color: var(--grey);
  text-align: center;
}

.infoNotice {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.copyright {
  text-align: center;
  color: var(--grey);
  font-size: 0.9rem;
}

.whiteText {
  color: white;
}

.custromJumbotron {
  border-radius: 0;
  padding-top: 0;
}

.custromJumbotron-Col {
  margin-top: 4rem;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.blockHeading {
  margin-bottom: 2rem;
}

.imageCaption {
  color: var(--grey);
  text-align: center;
  font-size: 0.9rem;
}

.noLs {
  text-align: center;
  color: #dd0a0a;
  font-size: 1.5rem;
}

.putOffer {
  color: #d59608;
}

.redeemed {
  color: #b40e0e;
}

.cookieNotice {
  background-color: #ebb71a;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.cookieNoticeText {
  font-size: 1.1rem;
  color: white;
}

.noSelect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.filterBlock {
  color: var(--grey);
  flex-basis: 15rem;
  align-items: center;
}

.filterBlockSm {
  width: 6rem !important;
  text-align: center;
}

.noWrap {
  white-space: nowrap !important;
}

.filterBlockName {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.filterSwitchBlock {
  flex-basis: 17rem;
  color: var(--grey);
}

.filterCalculateBlock {
  flex-basis: 15rem;
  color: var(--grey);
}

.filterLevelBlock {
  color: var(--grey);
  flex-basis: 10rem;
}

.filterNdflBlock {
  color: var(--grey);
  flex-basis: 17rem;
}

.filterNdflBlock label, 
.filterRealRatePeriodBlock label, 
.filterLevelBlock label, 
.filterCalculateBlock .form-check, 
.filterCalculateBlock label, 
.filterSwitchBlock label.custom-control-label {
  justify-content: flex-start !important;
}

.filterRealRatePeriodBlock {
  color: var(--grey);
  flex-basis: 20rem;
}

.filterNameBlock {
  color: var(--grey);
  flex-basis: 10rem;
}

.filterNameBlock textarea {
  min-height: 3rem;
  height: 7rem;
}

.hidden {
  display: none;
}

.extraFiltersButton {
  flex-basis: 5rem;
}

.percentChange {
  font-size: 0.7rem;
}

.percentUp {
  color: #3b813b;
}

.percentDown {
  color: #b83333;
}

.cursorHelp {
  cursor: help;
}

.fakeBorder {
  height: 1px;
  background-color: #dee2e6;
}

.fullWidth {
  width: 100%;
}

.extraFiltersHidden {
  display: none;
}

.allFiltersHidden {
  display: none;
}

.clear {
  clear: both;
}

.floatRight {
  float: right;
}

.minifiedForm {
  display: inline-block;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.rowSellNow {
  background-color: #fff3cd;
}

.feeSet {
  cursor: pointer;
  color: var(--warning) !important;
}

.minFeeSwitch {
  margin-bottom: 0.5rem;
}

.ndfl {
  color: var(--aqua);
}

.autoNkd {
  display: inline;
  color: var(--grey);
}

.feedbackMessage {
  min-height: 2.5rem;
}

.fullScreen {
  max-width: 100% !important;
}

.noHeaderLine {
  border-bottom: none !important;
}

.bold {
  font-weight: bold;
}

.navDropdownItem {
  white-space: nowrap;
  margin: 0 10px;
}

.privacyDate {
  font-size: 0.9rem;
}

.positionRelative {
  position: relative;
}

.chartTooltip {
	width: 130px;
	height: 55px;
	position: absolute;
	padding-top: 8px;
	box-sizing: border-box;
	font-size: 12px;
  background-color: white;
  text-align: center;
	z-index: 1000;
	top: 12px;
	left: 12px;
	pointer-events: none;
	border: 1px solid var(--lightAqua);
	border-radius: 3px;
}

.chartTooltipPrices {
  color: var(--grey);
  font-weight: bold;
}

.chartTooltipVolume {
  color: var(--lightAqua);
  font-weight: bold;
}

.chartTooltipHint {
  color: var(--grey);
  font-size: 10pt;
}

.intradayChartDate {
  position: absolute;
  z-index: 100;
  top: 1px;
  left: 5px;
  color: var(--grey);
  font-size: 11pt;
}